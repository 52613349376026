@import "../../../scss/variable.scss";
@import "../../../scss/mixin.scss";

.unauth-content {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: $secondary_grey;

  .login-image {
    border-radius: 50%;
    margin-right: 2rem;
    width: 90%;
    height: 80vh;
    margin-top: 10vh;
    float: right;
  }

  .vendify-logo-login {
    position: absolute;
    width: 225px;
    height: 48px;
    top: 4vh;
    left: 4vh;
  }

  .padlock-icon {
    width: 18px;
    height: 24px;
    transform: translateX(-8px);
  }

  .login-card {
    width: 35%;
    background-color: $secondary_grey;
    border-radius: 50px !important;
    padding: 2% 3% !important;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    @include outset;

    div.card-title {
      width: 100%;
      color: $dark_color;
      font-size: 30px;
      text-align: center;
      font-weight: $fnt500;
      font-family: $poppins;
    }
    div.forgot-password-box{
      width: 95%;
      margin-top: -5px;
      cursor: pointer;
    }
  }
}

.forgot-password-btn-link {
  color: $primary_link !important;
  float: right;
  font-size: 14px;
  font-family: $poppins;
  text-transform: inherit !important;
  transition: 0.5s !important;

  &:focus {
    outline: none;
    border: 0px;
  }
}

.input-login {
  div.MuiInputBase-root {
    border: none;
    @include inset;
    border-radius: 40px !important;
    padding: 8px 16px;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 8px;
    line-height: 20px;

    input {
      border: 0px;
      border-bottom: 0px;
      padding-top: 11px;
    }

    &:hover:before,
    &:focus:before,
    &:before,
    &:focus,
    &:after {
      border-bottom: 0px !important;
      border-color: transparent;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.login-submit-btn {
  width: 100%;
  padding: 16px !important;
  text-align: center;
  margin-top: 36px !important;
  color: $primary_text !important;
  border-radius: 50px !important;
  @include outset;

  &:focus {
    outline: none;
    border: 0px;
  }

  .MuiButton-label {
    font-size: 16px !important;
    font-family: $poppins;
  }
}

.disabled-login-btn {
  @include outset;
}

.padlock-icon {
  cursor: pointer !important;
}
@media screen and (max-width: 1330px) {
  .unauth-content .login-card {
    width: 40%;
  }
}
@media screen and (max-width: 991px) {
  .unauth-content .login-card {
    width: 40% !important;
    padding: 1% 2% !important;
  }
}

.email-login {
  input {
    text-transform: lowercase !important;
  }
}
