@import "../../../scss/variable.scss";

.disabled-btn {
  color: $disabled_grey !important;
}

.custom-btn:hover {
  background: red;
}

.center-button {
  justify-content: center;
}

.money-text-design {
  font-weight: 600;
}
