@import "../../../../scss/variable.scss";

$className: "float-activator";
$parentClass: "feedback-form";
$iconSize: 3em;
$wrapperWidth: 18em;

.#{$parentClass + "__" + $className} {
  font-size: 1rem;
  cursor: pointer;
  font-family: $poppins;
  position: fixed;
  right: 0;
  top: 2em;
  z-index: 1;
  height: $iconSize + 2;

  .#{$className + "__wrapper"} {
    position: relative;
    display: flex;
    align-items: center;
    background: $primary_link;
    width: $wrapperWidth;
    padding: 1.5em;
    padding-left: $iconSize - 1.5em;
    font-size: 1em;
    margin-right: -($wrapperWidth - ($iconSize + 2));
    height: inherit;
    border-top-left-radius: 3.5em;
    border-bottom-left-radius: 3.5em;
    transition: margin 0.5s ease-in-out;
    &:hover {
      margin-right: 0;
    }
    fill: white;
    color: white;
  }

  .#{$className + "__float-icon"} {
    width: $iconSize;
    margin-right: 2em;
  }
}
