.action-icon {
  width: auto;
  height: auto;
  padding: 0px !important;

  &:focus {
    outline: none;
    border: 0px;
  }

  img {
    width: 20px;
    height: 20px;
  }
}
