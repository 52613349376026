@import "../../../../scss/variable.scss";

$className: "form-submitted";
$parentClass: "feedback-form";

/*Css for modal when feedback form is submitted by an user*/
.#{$parentClass}.#{$className} {
  font-size: 0.9rem;
  font-family: $poppins;

  .#{$className + "__icon"} {
    width: 10em;
  }
  .#{$className + "__title"} {
    line-height: 2;
    color: $lightGreen;
    margin: 0.5em 0;
  }
  .#{$className + "__message"} {
    line-height: 1.5;
    font-weight: 400;
    margin: 0.5em 0;
  }
  .#{$className + "__btn"} {
    margin: 1em 0;
    width: 6em;
    border-radius: 5px;
    border: none;
    padding: 0.5em 0;
    background: $primary_link;
    color: white;
  }
}
