@import "../../../scss/variable.scss";
@import "../../../scss/mixin.scss";

.config-app__container {
  &:hover {
    background: unset !important;
  }

  .config-app__guest-password {
    border: none;
    padding: 0.5em 0.7em;
    border-radius: 5px;
    box-shadow: 0px 2px 6px #00000029;
    -webkit-box-shadow: 0px 2px 6px #00000029;
    -moz-box-shadow: 0px 2px 6px #00000029;
  }
}

.top-navbar {
  width: calc(100% - 275px);
  left: 275px;
  height: 70px !important;
  max-height: 70px !important;
  overflow: hidden !important;
  background: $secondary_grey 0% 0% no-repeat padding-box !important;
  padding: 5px 25px 5px 11px;
  @include outset;

  .feedback-form__icon {
    svg {
      fill: $primary_link;
    }
  }

  .slide-btn {
    margin-left: 16px;
    width: 45px;
    height: 45px;
    text-align: center;
    border-radius: 50%;
    transition: 0.5s;
    @include outset;
    margin-top: 5px;

    img {
      width: calc(100% - 0px);
      height: calc(100% - 10px);
    }

    &:hover {
      @include inset;
    }
  }

  .brand-navbar {
    font-size: 23px;
    font-family: $poppins;
    font-weight: 500;
    letter-spacing: 0px;
    color: $primary_link;
    margin: 11px 0px 0px 0px;
    line-height: 20px;
    margin-right: 10px;
  }
}

.top-navbar .slide-btn2 {
  text-align: center;
  border-radius: 0%;
  box-shadow: -3px -3px 7px #ffffffb2, 3px 3px 5px rgba(94, 104, 121, 0.42) !important;
  background: #ecf0f3 !important;
  transition: 0.5s;
  margin-left: 16px;
  margin-top: 5px;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 16px;
  color: $primary_link;
  font-weight: $fnt500;
  font-family: $poppins;
}

.slide-btn2 img {
  width: 20px;
  margin-right: 10px;
}

.MuiMenu-paper {
  box-shadow: 2px 2px 6px #dadada !important;
  margin-top: 2.5%;
}

.search {
  position: relative;
}

.searchIcon {
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 0px;
  right: 0;
}

.top-navbar-collapse {
  width: calc(100%);
  height: 70px !important;
  max-height: 70px !important;
  overflow: hidden !important;
  background: $secondary_grey 0% 0% no-repeat padding-box !important;
  padding: 5px 25px 5px 11px;
  transition: 0.5s;
  @include outset;

  .slide-btn {
    width: 45px;
    height: 45px;
    text-align: center;
    border-radius: 50%;
    @include outset;
    margin: 5px 0px 0px 16px;

    img {
      width: calc(100% - 0px);
      height: calc(100% - 10px);
    }

    &:hover {
      @include inset;
    }
  }

  .brand-navbar {
    font-size: 23px;
    font-family: $poppins;
    font-weight: 500;
    letter-spacing: 0px;
    color: $primary_link;
    margin: 11px 0px 0px 0px;
    line-height: 40px;
    margin-right: 10px;
  }

  .feedback-form__icon svg {
    fill: #4b6e86;
    transition: 0.5s;
  }
}

.top-navbar-collapse .slide-btn2 {
  text-align: center;
  border-radius: 0%;
  box-shadow: -3px -3px 7px #ffffffb2, 3px 3px 5px rgba(94, 104, 121, 0.42) !important;
  background: #ecf0f3 !important;
  margin-top: 5px;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 16px;
  color: $primary_link;
  font-weight: $fnt500;
  font-family: $poppins;
}

@keyframes grow {
  0% {
    max-width: calc(100% - 275px);
    left: 275px;
  }

  50% {
    max-width: calc(100% - 150px);
    left: 150px;
  }

  100% {
    max-width: calc(100% - 100px);
    left: 100px;
  }
}

@keyframes grow2 {
  0% {
    max-width: calc(100% - 100px);
    left: 100px;
  }

  50% {
    max-width: calc(100% - 150px);
    left: 150px;
  }

  100% {
    max-width: calc(100% - 275px);
    left: 275px;
  }
}

@media only screen and (max-width: 1280px) {
  .navbar-collapse .left-margin img {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  i.feedback-form__icon {
    width: 0.8em;
  }

  .top-navbar .brand-navbar {
    font-size: 20px;
    margin-right: 10px;
  }

  .top-navbar .slide-btn {
    width: 38px;
    height: 38px;
    margin-top: 10px;
  }

  .top-navbar .slide-btn img {
    width: calc(100% - -3px);
    height: calc(100% - 10px);
  }

  .top-navbar .slide-btn2 {
    font-size: 13px;
    transform: translateY(2px);
  }
}

.btn-dropdown {
  width: auto;
}

@media only screen and (max-width: 991px) {
  .top-navbar .brand-navbar {
    font-size: 16px;
    margin-right: 10px;
  }
}

.top-navbar-collapse.MuiDrawer-paperAnchorTop {
  transition: 0.5s ease;
}

.top-navbar.MuiDrawer-paperAnchorTop {
  left: 275px;
  transition: 0.5s ease;
}

.notification-title {
  font-size: 20px;
  font-weight: $fnt500;
  color: $black;
  padding: 10px 20px 10px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  background: #fafafa;
}

.profile-title {
  font-size: 16px;
  font-weight: $fnt500;
  color: $white;
  padding: 7px 20px 7px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  background: #4b6e86;
  font-family: $poppins;
}

.view-all-button {
  text-align: center;
  padding: 5px 0px;
  border-top: 1px solid #dadada;
  margin-bottom: 0;
  cursor: pointer;
  color: $primary_text;
}

.notification-content-box {
  font-family: $poppins;
  background: #fafafa;
  border: 2px solid #dadada;
  padding: 6px 15px;
  border-radius: 5px;
  width: 400px;
  margin-left: 0;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 0px;

  .notification-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: $fnt500;
      text-transform: capitalize;
      width: 100%;
      text-overflow: ellipsis !important;
      overflow: hidden !important;

      a {
        color: $primary_text;
      }

      small {
        font-weight: $fnt500;
        font-size: 12px;
        color: $black;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: #000;

      img {
        width: 14px;
        transform: translateY(-1px);
      }
    }
  }

  .notification-body {
    p {
      margin-bottom: 3px;
      font-size: 12px !important;
      margin-top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .notification-indicator {
    position: absolute;
    width: 5px;
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    content: "";
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

#simple-menu-notification .MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.menu-item {
  font-family: $poppins;
}

.menu-item-profile {
  width: 19%;
  top: 4.2% !important;
}

.menu-item-profile li {
  font-size: 14px;
  padding: 10px 10px;
  color: $black;
  border-bottom: 1px solid #dadada;
  cursor: initial;
}

.menu-item-profile li:nth-child(3) span {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}

.menu-item-profile li img {
  width: 20px;
  margin-right: 10px;
}

//Logout button
.menu-item-profile li.logout-user {
  cursor: pointer;

  span {
    background-image: url("../../../assets/icons/off.svg");
    background-repeat: no-repeat;
    width: 20px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  &:hover span {
    background-image: url("../../../assets/icons/power-off_white.svg");
  }

  &:hover {
    background: $primary_text;
    color: $white;
  }
}

.user-profile-card__company-name {
  font-weight: 600;
  font-family: $poppins;
}

@media only screen and (max-width: 1300px) {
  .user-profile-card__company-name.ellipsis {
    max-width: 150px;
  }
}
