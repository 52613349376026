@import "../../../scss/variable.scss";
@import "../../../scss/mixin.scss";

.card-container {
  width: calc(100% - 48px);
  margin: auto;
  margin-top: 16px;
  border-radius: 10px !important;
  margin-bottom: 25px;

  .left-arrow {
    width: 31px;
    height: 28px;
    transform: translateY(-1px);
    cursor: pointer;
  }

  .card-brand {
    color: $title_black;
    font-size: 23px;
    font-family: $poppins;
    font-weight: 500;
    transform: translateY(4px);
    text-transform: capitalize;
  }

  .add-btn {
    color: $white;
    background-color: $primary_text;

    .add-icon {
      width: 16px;
      height: 16px;
      transform: translateY(1px);
      margin-right: 4px;
    }

    &:hover {
      background: #da4127;
    }
  }

  .bulk-btn-10 {
    margin-bottom: 10px;
  }

  .cardContainer-btn {
    display: flex;
    justify-content: flex-end;
    width: auto;
    gap: 5px;
  }
}

@media only screen and (max-width: 1330px) {
  .card-container .card-brand {
    font-size: 20px;
    transform: translateY(9px);
  }

  .card-container .add-btn {
    margin-bottom: 10px;
  }
}
