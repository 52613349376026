@import "../../../scss/variable.scss";

.title-permission {
  width: 100%;
  display: block;
  text-align: left;
  font-size: 25px;
  font-family: $poppins;
  font-weight: 500;
  color: $title_black;
  padding: 25px 20px;
  margin-bottom: 0px;
}
.header-permission-box {
  background-color: #efefef;
  font-size: 14px;
  font-weight: 500;
  color: $title_black;
  font-family: $poppins;
  padding-top: 10px;
  padding-bottom: 10px;

  .header-title {
    padding: 10px 25px 10px 25px;
  }
}

.horizontal-header {
  padding: 25px 20px;
  margin-top: 13px !important;
  font-size: 16px;
  text-align: center;
}

.permission-checkbox-container {
  text-align: center;
  padding-top: 1px;
  border-bottom: 1px solid #dadada;
}

.permission-checkbox-checked {
  color: $primary_text !important;
}
@media only screen and (max-width: 1200px) {
  .permission-checkbox-container {
    width: 25%;
  }
  .title-permission {
    font-size: 20px;
    padding: 29px 20px;
  }
  .header-permission-box .header-title {
    padding: 10px 10px 10px 15px;
  }
}
