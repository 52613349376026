@import "./variable.scss";

.custom-card {
  width: 97%;
  background: $white;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  font-family: $poppins;

  .chart-headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    z-index: 1;
    position: relative;

    h1 {
      font-size: 18px;
      color: $title_black;
      width: 45%;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      list-style: none;
      width: 35px;
      height: 29px;
      line-height: 26px;
      text-align: center;
      color: #4b6e86;
      border: 2px solid #4b6e86;
      font-size: 13px;
      font-weight: 500;
      transform: translateY(-1px);
      margin-right: 8px;
      border-radius: 3px;
      font-weight: $fnt500;
      cursor: pointer;

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        border: none;
      }

      &:nth-child(5),
      &:nth-child(6) {
        background: none;
        color: none;
      }
    }

    li.active {
      background: $primary_link;
      color: $white;
    }
  }

  .chart-body {
    margin-top: 7%;
    .custom-table {
      margin-top: 0 !important;
    }
  }
  .dash-table {
    height: calc(100vh - 60vh);
    overflow-y: scroll;
  }

  .custom-card-footer {
    margin-top: 15px;
    padding-top: 8px;
    border-top: 1px solid $secondary_grey;

    ul {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 60% 50%;

      li {
        list-style: none;
        position: relative;
        font-size: 14px;
        margin-left: 4%;

        &.active {
          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: green;
            left: -15px;
            top: 6px;
            border-radius: 50%;
          }
        }

        &.inactive {
          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: red;
            left: -15px;
            top: 6px;
            border-radius: 50%;
          }
        }

        &.Inprogress {
          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: $warningClr;
            left: -15px;
            top: 6px;
            border-radius: 50%;
          }
        }

        &.dispatched {
          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: $subtitle;
            left: -15px;
            top: 6px;
            border-radius: 50%;
          }
        }

        &.order-created {
          &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: $commonLinkClr;
            left: -15px;
            top: 6px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.chart-label {
  &::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    left: -15px;
    top: 6px;
    border-radius: 50%;
  }
  &.label--lightgreen {
    &::before {
      background: #00ff00;
    }
  }
  &.label--cyan {
    &::before {
      background: #00ffff;
    }
  }
  &.label--darkred {
    &::before {
      background: #880808;
    }
  }
}

.custom-card-image {
  cursor: pointer;
  width: 69px;
}

// new gridbox
.wrap-charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.chart-body-franchise canvas {
  height: 200px !important;
  width: 400px !important;
  margin: 0 auto;
}
@media only screen and (max-width: 1366px) {
  .custom-card .chart-headings h1 {
    width: 40%;
  }
  .custom-card .dash-table {
    height: calc(100vh - 65vh);
  }
  .chart-body-franchise canvas {
    height: 155px !important;
    width: 300px !important;
  }
}
@media only screen and (max-width: 1335px) {
  .custom-card .chart-headings h1 {
    width: 40%;
  }
  .custom-card .dash-table {
    height: calc(100vh - 65vh);
  }
  .chart-body-franchise canvas {
    height: 140px !important;
    width: 300px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .custom-card .chart-headings li {
    width: 30px;
  }
  .custom-card .custom-card-footer ul {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 1200px) {
  .custom-card .chart-headings h1 {
    font-size: 18px !important;
    font-family: $poppins !important;
  }
  .custom-card .chart-headings li {
    width: 25px;
    height: 24px;
    line-height: 20px;
    font-size: 12px;
    transform: translateY(-10px);
  }
  .chart-body {
    margin-top: 8% !important;
  }
  .card-height {
    height: 29vh !important;
  }
  .custom-card-footer {
    margin-top: 0px !important;
  }
  .card-height .custom-card-footer ul {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .card-height .custom-card-footer ul li {
    width: auto !important;
  }
}

@media only screen and (max-width: 991px) {
  .wrap-charts {
    display: grid;
    grid-template-columns: 1fr;
  }
}
