@import "../../../scss/variable.scss";

.delete-modal {
  width: 601px;
  height: 407px;
}
.delete-modal-custom-height {
  height: 470px;
}
.delete-popup-icon {
  width: 100px;
  height: 100px;
  margin-top: 52px;
}

.delete-dialog-content {
  font-size: 18px;
  font-weight: normal;
  font-family: $poppins;
  color: #707070;

  h3 {
    font-size: 32px;
    color: $title_black;
  }

  label {
    color: $primary_text;
  }
}

.cancel-btn {
  width: auto;
  height: 44px;
  background: $disabled_grey 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  color: $white !important;
  font-size: 15px;
  font-weight: normal;
  font-family: $poppins;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.delete-btn {
  background-color: $deleteBtnClr !important;
}

.dont-log-out {
  background-color: $primary_text !important;
}

.got-it-btn {
  background-color: $editBtnClr !important;
}

.add-container-modal {
  max-width: 550px !important;
  width: 550px;

  .add-container-title {
    padding: 10px 42px 10px 23px;
    background-color: $secondary_grey;
    color: $primary_link;

    h2 {
      font-size: 22px;
      font-weight: 500 !important;
      font-family: $poppins;
    }

    .cross-icon-link {
      width: 20px;
      height: 20px;
      transform: translateY(7px);
      cursor: pointer;
      float: right !important;
    }
  }

  .add-container-content {
    padding: 0px;
  }
}

.add-bulk-upload-customer-modal {
  max-width: 380px !important;
  width: 380px;

  .add-container-title {
    padding: 10px 42px 10px 23px;
    background-color: $secondary_grey;
    color: $primary_link;

    h2 {
      font-size: 22px;
      font-weight: 500 !important;
      font-family: $poppins;
    }

    .cross-icon-link {
      width: 20px;
      height: 20px;
      transform: translateY(7px);
      cursor: pointer;
      float: right !important;
    }
  }

  .add-container-content {
    padding: 0px;
  }
}

.refund-container-modal {
  max-width: 1100px !important;
  width: 1100px;

  .add-container-title {
    padding: 10px 42px 10px 23px;
    background-color: $secondary_grey;
    color: $primary_link;

    h2 {
      font-size: 22px;
      font-weight: 500 !important;
      font-family: $poppins;
    }

    .cross-icon-link {
      width: 20px;
      height: 20px;
      transform: translateY(7px);
      cursor: pointer;
      float: right !important;
    }
  }

  .add-container-content {
    padding: 0px;
  }
}

.add-container-action-btns {
  width: 100%;
  height: 100%;
  background-color: $secondary_grey;
  margin-top: 18px;
  padding-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 10px;
  text-align: right;
}

.file-instructions {
  font-size: 12px;
  color: #707070;
  font-weight: normal;
  font-family: $poppins;

  span {
    font-weight: 400;
  }
}

.horizontal-bar {
  width: calc(100% - 40px);
  height: 0px;
  border-top: 1px solid #000000;
  display: inline-block;
  margin-left: 20px;
  transform: translateY(10px);
}

.learn-more {
  padding: 0px !important;
  margin-left: 4px !important;
}

.download-schema-btn {
  border: 1px solid #707070 !important;
  background-color: rgba(112, 112, 112, 0.7) !important;
  color: $title_black !important;
}

.bulk-import-action-container {
  background-color: $secondary_grey;
  text-align: left !important;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 18px;
  justify-content: space-between !important;
}

.bulk-import-action-container-for-refund {
  background-color: $secondary_grey;
  text-align: left !important;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 18px;
  justify-content: end !important;
}

.bulk-import-file {
  border: 1px solid #707070;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
}

.schema-container {
  width: 100%;
  padding: 12px;
  border: 1px solid #6fafef;
  border-radius: 6px;
  background-color: #cadcef;
  font-size: 12px;
  font-weight: 600;
  font-family: $poppins;
  margin-bottom: 14px;
}
.modalBody {
  padding: 10px 30px 30px;
  .infoHelp {
    float: right;
    font-size: 10px;
    margin-bottom: 10px;
    color: $black;
    font-weight: $fnt500;

    .infoIcon {
      width: 12px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }
}
.columnMain {
  position: relative;
}

.vending-machine-column-configure__depth-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
}

.columnKey {
  position: absolute;
  margin-top: -30px;
}
.columnBox {
  position: relative;
  height: 210px;
  width: 210px;
  background: linear-gradient(#ecf0f3, #dee2e6);
  border: 1px solid #707070;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 10px 20px;
  padding: 5px;

  &.white-bg {
    background: white;
  }
  .icon {
    color: #ffffff;
    cursor: pointer;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    margin-right: 5px;
    &.add-icon {
      background: #58b14e;
      &:hover {
        background: #58b14e !important;
      }
    }
    &.edit-icon {
      background: #3987fd;
      &:hover {
        background: #3987fd !important;
      }
    }
    &.delete-icon {
      background: red;
      &:hover {
        background: red !important;
      }
    }
  }
  /*CSS till product is not added*/
  .add-product-container {
    display: flex;
    justify-content: space-between;

    .add-product-msg {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }

  .product-container {
    .product-container__header {
      display: flex;
      justify-content: space-between;
    }

    .product-container__details {
      text-align: center;
      font-size: 15px;
      .head {
        font-weight: bold;
        color: $primary_link;
        margin: 0;
      }
      .product-image {
        width: 100%;
        margin: 0 auto;
        text-align: center;

        img {
          margin: 0 auto;
        }
        .product-name {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 60%;
          white-space: nowrap;
          position: relative;
          margin: 0 auto;
        }
      }
      .product-price-depth {
        margin-top: 40px;
        display: flex;
        justify-content: space-evenly;
      }

      .product-runout {
        text-align: center;
      }
    }
  }

  .btn-merge {
    position: absolute;
    right: -40px;
    top: 50%;
    margin-top: -20px;
    z-index: 10;
  }

  .btn-split {
    position: absolute;
    left: 10px;
    bottom: 10px;
    margin-top: -20px;
    z-index: 10;
  }

  .columnHead {
    font-weight: $fnt500;
    color: $black;
  }

  .columnText {
    text-transform: capitalize;
    font-weight: $fnt500;
    color: $black;
    margin-bottom: 5px;
  }
  &.columnMerged {
    width: 310px;
  }
}
.add-dynamic-container {
  width: auto;
  max-width: 100% !important;
}
.activiti-log {
  width: fit-content;
  max-width: 1000px !important;
}
.activity-table {
  width: 100%;
  padding: 20px 0px 30px 0px;
}
.activity-table table {
  th {
    font-size: 16px;
    font-weight: $fnt500;
    color: $black;
    padding: 10px 10px;
    background-color: $secondary_grey;
  }
  td {
    font-size: 14px;
    color: $black;
    padding: 10px;
  }
}
.custom-remarks-list {
  text-align: left;
  padding-left: 15px;
}
.modal-span {
  color: $primary_text;
}
.text-modal {
  font-family: $poppins;
  font-size: 14px;
  font-weight: 500;
}
.deliver-partner-name {
  text-align: left !important;
  font-weight: $fnt500;
  img {
    width: 20px;
    margin-right: 5px;
  }
}
.add-shipping-btn-enabled {
  color: #2c8300 !important;
}
.bulk-stock-instructions {
  padding: 20px;
}
.bulk-stock-instructions ul {
  margin: 0;
  padding: 0;
}
.bulk-stock-instructions ul li {
  font-size: 16px;
  text-align: left;
  color: #000;
  line-height: 30px;
  margin-left: 20px;
}
.bulk-stock-instructions ul li:first-child {
  border-bottom: 1px solid #dadada;
  list-style: none;
  margin-bottom: 10px;
  margin-left: 0px;
}
.bulk-stock-instructions ul li span {
  color: $primary_text;
}
.text-modal .add-btn {
  margin-left: 10px;
}
.Default-row-depth-input-gap {
  flex-basis: 7% !important;
}

.update-refill-plan {
  //Message to be shown for expired products
  .update-refill-plan__expired-products {
    color: $errorClr;
    font-size: 15px;

    .message-title {
      font-weight: bold;
      color: $disabled_grey;
    }
  }
}

.activity-log__products-table .products-table__head {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #222;
}

.activity-log__products-table .products-table__head > div {
  width: 500px;
  text-align: center;
}

.activity-log__products-table .products-table__head .head-difference {
  border-left: 1px solid #222;
}

.activity-log__products-table .products-table__data .data-product {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  border: 1px solid #222;
  border-top: none;
}

.activity-log__products-table
  .products-table__data
  .data-product
  .product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
}

.activity-log__products-table
  .products-table__data
  .data-product
  .product-difference {
  width: 500px;
  border-left: 1px solid #222;
}

.add-container-modal.refund-modal {
  width: 1000px;
  max-width: unset !important;
  .add-screen-formcontrol {
    margin-left: -7px !important;
  }

  .total-amount-row {
    background: #e6e6e6;
  }
}

.add-container-modal.refund-modal .refund-completed {
  td {
    color: #075a09 !important;
    span {
      color: #075a09 !important;
    }
  }

  .permission-checkbox-checked {
    color: #075a09 !important;
  }
}
